import "%modules%/header/header";
import "%modules%/main_head/main_head";
import "%modules%/main_reds/main_reds";
import "%modules%/main_rmap/main_rmap";
import "%modules%/main_slider/main_slider";
import "%modules%/main_blocks/main_blocks";
import "%modules%/main_red/main_red";
import "%modules%/main_pref/main_pref";
import "%modules%/main_first/main_first";
import "%modules%/main_sl/main_sl";
import "%modules%/main_contacts/main_contacts";
import "%modules%/team_content/team_content";
import "%modules%/notfound_page/notfound_page";
import "%modules%/docs__content/docs__content";
import "%modules%/news_blocks/news_blocks";
import "%modules%/news_page_content/news_page_content";
import "%modules%/kladovie_first/kladovie_first";
import "%modules%/kladovie_plan/kladovie_plan";
import "%modules%/kladovie_sl/kladovie_sl";
import "%modules%/kladovie_blocks/kladovie_blocks";
import "%modules%/parking_first/parking_first";
import "%modules%/parking_blocks/parking_blocks";
import "%modules%/parking_white/parking_white";
import "%modules%/act_page_content/act_page_content";
import "%modules%/buy_tabs/buy_tabs";
import "%modules%/dinamic_main/dinamic_main";
import "%modules%/main_func/main_func";
import "%modules%/floor_first/floor_first";
import "%modules%/floors/floors";
import "%modules%/room_first/room_first";
import "%modules%/room_blocks/room_blocks";
import "%modules%/din_in_page/din_in_page";
import "%modules%/din_page/din_page";
import "%modules%/ap_first/ap_first";
import "%modules%/gal_blocks/gal_blocks";
import "%modules%/vid_blocks/vid_blocks";
import "%modules%/params_blocks/params_blocks";
import "%modules%/parking_white2/parking_white2";
import "%modules%/komc_first/komc_first";
import "%modules%/komc_blocks/komc_blocks";
import "%modules%/parking_white3/parking_white3";
import "%modules%/komc_in_white/komc_in_white";
import "%modules%/footer/footer"